import { alpha } from '@mui/material';
import { colorTokens } from './colorTokens';
export const getPalette = (mode) => {
    const { slate, zinc, indigo, grey, red, orange, amber, yellow, green, emerald, teal, cyan, sky, violet, blue, purple, fuchsia, pink, rose, lime, } = colorTokens;
    return {
        mode,
        ...(mode === 'light'
            ? {
                // palette values for light mode
                //contrastThreshold: 4.5,
                Avatar: {
                    defaultBg: zinc[500],
                },
                Tooltip: {
                    bg: zinc[700],
                },
                grey: grey,
                slate: slate,
                zinc: zinc,
                indigo: indigo, // only used for primary not having indigo[200]
                purple: purple,
                //  Avatar: {},
                action: {
                // hover: zinc[100],
                },
                background: {
                    // default: grey[100],
                    default: zinc[100],
                    // light: grey[50],
                    light: zinc[50],
                },
                // divider: grey[100], //amber[200],
                divider: alpha(zinc[950], 0.1),
                dividerTranslucent: `color-mix(in oklab, var(--gp-palette-text-primary) 5%, transparent)`,
                text: {
                    // primary: grey[900],
                    // secondary: grey[800],
                    // medium: grey[700],
                    // light: grey[600],
                    // muted: grey[500],
                    // disabled: grey[400],
                    primary: zinc[950],
                    secondary: zinc[800],
                    medium: zinc[700],
                    light: zinc[600],
                    muted: zinc[500],
                    disabled: zinc[400],
                    translucent: zinc[300],
                },
                neutral: {
                    // dark: grey[700],
                    // main: grey[500],
                    // light: grey[300],
                    // lighter: grey[200],
                    // //lightest: grey[100],
                    dark: zinc[700],
                    main: zinc[500],
                    light: zinc[300],
                    lighter: zinc[200],
                    //lightest: grey[100],
                    contrastText: '#ffffff', // <Chip color="neutral" /> fails without this.
                },
                primary: {
                    // black 900
                    // darkest 800
                    darker: indigo[700],
                    dark: indigo[600],
                    main: indigo[500],
                    light: indigo[400],
                    lighter: indigo[300],
                    lighter200: indigo[200],
                    lightest: indigo[100],
                    background: indigo[100],
                    lightest50: indigo[50],
                    backgroundLight: indigo[50],
                },
                secondary: {
                    // darkest: purple[800],
                    darker: purple[700],
                    dark: purple[600],
                    main: purple[500],
                    light: purple[400],
                    lighter: purple[300],
                    lighter200: purple[200],
                    lightest: purple[100],
                    background: purple[100],
                    lightest50: purple[50],
                    backgroundLight: purple[50],
                },
                info: {
                    darker: blue[700],
                    dark: blue[600],
                    main: blue[500],
                    default: blue[500],
                    light: blue[400],
                    lighter: blue[300],
                    //lighter200: blue[200],
                    lightest: blue[100],
                    background: blue[100],
                    lightest50: blue[50],
                    backgroundLight: blue[50],
                },
                success: {
                    darker: green[700],
                    dark: green[600],
                    main: green[500],
                    default: green[500],
                    light: green[400],
                    lighter: green[300],
                    lighter200: green[200],
                    lightest: green[100],
                    background: green[100],
                    lightest50: green[50],
                    backgroundLight: green[50],
                },
                warning: {
                    darker: orange[700],
                    dark: orange[600],
                    main: orange[500],
                    default: orange[500],
                    light: orange[400],
                    lighter: orange[300],
                    lighter200: orange[200],
                    lightest: orange[100],
                    background: orange[100],
                    lightest50: orange[50],
                    backgroundLight: orange[50],
                },
                error: {
                    darker: red[700],
                    dark: red[600],
                    default: red[500],
                    main: red[500],
                    light: red[400],
                    lighter: red[300],
                    lighter200: red[200],
                    // lightest: red[200],
                    lightest: red[100],
                    background: red[100],
                    backgroundLight: red[50],
                    // TODO: check why typescript not working.
                    bad: red[50],
                    contrastText: yellow[100], // <Chip color="error" /> fails without this.
                },
                // orange: {
                // 	darker: orange[700],
                // 	dark: orange[600],
                // 	main: orange[500],
                // 	light: orange[400],
                // 	lighter: orange[300],
                // 	lightest: orange[200],
                // 	background: orange[100],
                // 	backgroundLight: orange[50],
                // 	contrastText: orange[100],
                // },
                // amber: {
                // 	darker: amber[700],
                // 	dark: amber[600],
                // 	main: amber[500],
                // 	light: amber[400],
                // 	lighter: amber[300],
                // 	lightest: amber[200],
                // 	background: amber[100],
                // 	backgroundLight: amber[50],
                // 	contrastText: amber[100],
                // },
                // yellow: {
                // 	darker: yellow[700],
                // 	dark: yellow[600],
                // 	main: yellow[500],
                // 	light: yellow[400],
                // 	lighter: yellow[300],
                // 	lightest: yellow[200],
                // 	background: yellow[100],
                // 	backgroundLight: yellow[50],
                // 	contrastText: yellow[100],
                // },
                // lime: {
                // 	darker: lime[700],
                // 	dark: lime[600],
                // 	main: lime[500],
                // 	light: lime[400],
                // 	lighter: lime[300],
                // 	lightest: lime[200],
                // 	background: lime[100],
                // 	backgroundLight: lime[50],
                // 	contrastText: lime[100],
                // },
                // emerald: {
                // 	darker: emerald[700],
                // 	dark: emerald[600],
                // 	main: emerald[500],
                // 	light: emerald[400],
                // 	lighter: emerald[300],
                // 	lightest: emerald[200],
                // 	background: emerald[100],
                // 	backgroundLight: emerald[50],
                // 	contrastText: emerald[100],
                // },
                // teal: {
                // 	darker: teal[700],
                // 	dark: teal[600],
                // 	main: teal[500],
                // 	light: teal[400],
                // 	lighter: teal[300],
                // 	lightest: teal[200],
                // 	background: teal[100],
                // 	backgroundLight: teal[50],
                // 	contrastText: teal[100],
                // },
                // cyan: {
                // 	darker: cyan[700],
                // 	dark: cyan[600],
                // 	main: cyan[500],
                // 	light: cyan[400],
                // 	lighter: cyan[300],
                // 	lightest: cyan[200],
                // 	background: cyan[100],
                // 	backgroundLight: cyan[50],
                // 	contrastText: cyan[100],
                // },
                // sky: {
                // 	darker: sky[700],
                // 	dark: sky[600],
                // 	main: sky[500],
                // 	light: sky[400],
                // 	lighter: sky[300],
                // 	lightest: sky[200],
                // 	background: sky[100],
                // 	backgroundLight: sky[50],
                // 	contrastText: sky[100],
                // },
                // fuchsia: {
                // 	darker: fuchsia[700],
                // 	dark: fuchsia[600],
                // 	main: fuchsia[500],
                // 	light: fuchsia[400],
                // 	lighter: fuchsia[300],
                // 	lightest: fuchsia[200],
                // 	background: fuchsia[100],
                // 	backgroundLight: fuchsia[50],
                // 	contrastText: fuchsia[100],
                // },
                // pink: {
                // 	darker: pink[700],
                // 	dark: pink[600],
                // 	main: pink[500],
                // 	light: pink[400],
                // 	lighter: pink[300],
                // 	lightest: pink[200],
                // 	background: pink[100],
                // 	backgroundLight: pink[50],
                // 	contrastText: pink[100],
                // },
                // rose: {
                // 	darker: rose[700],
                // 	dark: rose[600],
                // 	main: rose[500],
                // 	light: rose[400],
                // 	lighter: rose[300],
                // 	lightest: rose[200],
                // 	background: rose[100],
                // 	backgroundLight: rose[50],
                // 	contrastText: rose[100],
                // },
                orange: assignPaletteColors(orange),
                amber: assignPaletteColors(amber),
                yellow: assignPaletteColors(yellow),
                lime: assignPaletteColors(lime),
                emerald: assignPaletteColors(emerald),
                teal: assignPaletteColors(teal),
                cyan: assignPaletteColors(cyan),
                sky: assignPaletteColors(sky),
                violet: assignPaletteColors(violet),
                fuchsia: assignPaletteColors(fuchsia),
                pink: assignPaletteColors(pink),
                rose: assignPaletteColors(rose),
                /*
                    orderStatus: {
                        // 1
                        // ordered: { link: grey[900], linkHover: grey[800], chip: grey[600], chipBackground: grey[100] } as OrderStatusColor,
                        ordered: { link: grey[900], linkHover: grey[800], chipLabel: zinc[700], chipBackground: alpha(zinc[600], 0.1) } as OrderStatusColor,

                        // 2: "Отправлен поставщику",
                        sentToSupplier: { link: yellow[600], linkHover: yellow[500], chipLabel: yellow[700], chipBackground: yellow[100] } as OrderStatusColor,

                        // 4: "Подтвержден поставщиком",
                        //		validatedBySupplier: StatusColor;

                        // 5: "Отменен поставщиком",
                        // canceledBySupplier: { link: red[500], linkHover: red[400], chip: red[700], chipBackground: red[100] } as OrderStatusColor,
                        // canceledBySupplier: { link: red[500], linkHover: red[400], chip: red[700], chipBackground: alpha(red[500], 0.15) } as OrderStatusColor,
                        canceledBySupplier: {
                            link: orange[700],
                            linkHover: orange[500],
                            // chipLabel: orange[700],
                            // chipBackground: alpha(orange[500], 0.15),
                            chipLabel: yellow[700],
                            chipBackground: alpha(yellow[500], 0.15),
                        } as OrderStatusColor,

                        // 6: "Ожидается на складе testtestov",
                        expectedInWarehouse: {
                            link: purple[500],
                            linkHover: purple[400],
                            chipLabel: purple[700],
                            chipBackground: purple[100],
                        } as OrderStatusColor,

                        // 7: "Получен",
                        //received: { main: green[500], hover: green[400] },

                        // 8: "На складе testtestov",
                        inWarehouse: { link: blue[500], linkHover: blue[400], chipLabel: blue[700], chipBackground: blue[100] } as OrderStatusColor,

                        // 9: "Отправлен заказчику",
                        sentToCustomer: { link: green[600], linkHover: green[500], chipLabel: green[700], chipBackground: green[100] } as OrderStatusColor,

                        // 12: "Отменен администратором",
                        // canceledByAdmin: { link: red[700], linkHover: red[600], chip: red[700], chipBackground: red[100] } as OrderStatusColor,
                        canceledByAdmin: { link: red[700], linkHover: red[600], chipLabel: red[700], chipBackground: alpha(red[500], 0.15) } as OrderStatusColor,

                        // 13: "Запрошен возврат",
                        //returnRequested: StatusColor;

                        // 14: "Возврат одобрен, ожидается на складе",
                        //returnApproved: StatusColor;

                        // 15: "Возврат отклонен",
                        //returnRejected: StatusColor;

                        // 16: "Возврат принят на складе",
                        //returnAccepted: StatusColor;

                        // 17: "Отменен заказчиком",
                        //canceledByCustomer: StatusColor;

                        // 18: "Принят в ПВЗ",
                        //acceptedInShipmentPoint: StatusColor; // Pickup point?

                        // 19: "Передан в службу доставки",
                        //transferredToDeliveryService: StatusColor;

                        // 20: "Передан в службу доставки (собств.)"
                        //transferredToOwnDeliveryService: StatusColor;
                    },
                    */
                stockType: {
                    stock: {
                        main: purple[500],
                        // purple[300] lightened by 20%
                        stored: '#e0c3fe',
                        // purple[400] lightened by 20%
                        occupied: '#cd9dfd',
                        // mixed blue and purple
                        storedSelected: '#C2ADFD',
                        // mixed blue and purple
                        occupiedSelected: '#9A83FA',
                        occupiedBorder: purple[950],
                    },
                    crossdocking: {
                        main: yellow[400],
                        stored: yellow[200],
                        occupied: yellow[300],
                        // mixed blue[500] and yellow[300] take 30%
                        storedSelected: '#DACF67',
                        // mixed blue[500] and yellow[300] take 50%
                        occupiedSelected: '#B6BE87',
                        occupiedBorder: yellow[700],
                    },
                    // // Same as stock right now
                    // transfer: {
                    // 	main: purple[500],
                    // 	stored: purple[300],
                    // 	occupied: purple[400],
                    // 	// mixed blue and purple
                    // 	storedSelected: '#C2ADFD',
                    // 	// mixed blue and purple
                    // 	occupiedSelected: '#9A83FA',
                    // 	occupiedBorder: purple[950],
                    // } as StockTypeColor,
                },
                // } as PaletteColorOptions)
            } // & CssVarsPalette)
            : // Palette values for DARK mode
                {
                    Tooltip: {
                        bg: zinc[600],
                    },
                    grey: grey,
                    slate: slate,
                    zinc: zinc,
                    indigo: indigo, // only used for primary not having indigo[200]
                    purple: purple,
                    //  Avatar: {},
                    action: {
                    // hover: zinc[100],
                    },
                    background: {
                        // default: zinc[100],
                        default: zinc[950],
                        // light: zinc[50],
                        light: zinc[900],
                    },
                    // not working for some reason, error in console and app is dead.
                    // divider: 'color-mix(in oklab, var(--gp-palette-common-white) 10%, transparent)', // alpha(zinc[950] as string, 0.1),
                    divider: alpha('#ffffff', 0.1),
                    dividerTranslucent: 'color-mix(in oklab, var(--gp-palette-common-white) 5%, transparent)',
                    text: {
                        // primary: grey[900],
                        // secondary: grey[800],
                        // medium: grey[700],
                        // light: grey[600],
                        // muted: grey[500],
                        // disabled: grey[400],
                        // primary:  zinc[950],
                        // primary: '#fff',
                        primary: zinc[50], // catalyst uses 'white' color
                        // secondary: zinc[800],
                        secondary: zinc[100],
                        // medium: zinc[700],
                        medium: zinc[200],
                        // light: zinc[600],
                        light: zinc[300],
                        // muted: zinc[500],
                        muted: zinc[400],
                        // disabled: zinc[400],
                        disabled: zinc[500],
                        // translucent: zinc[300],
                        translucent: zinc[600],
                    },
                    neutral: {
                        // dark: grey[700],
                        // main: grey[500],
                        // light: grey[300],
                        // lighter: grey[200],
                        // //lightest: grey[100],
                        dark: zinc[700],
                        main: zinc[500],
                        light: zinc[300],
                        lighter: zinc[200],
                        //lightest: grey[100],
                        contrastText: '#ffffff', // <Chip color="neutral" /> fails without this.
                    },
                    primary: {
                        // black 900
                        // darkest 800
                        darker: indigo[700],
                        dark: indigo[600],
                        main: indigo[500],
                        light: indigo[400],
                        lighter: indigo[300],
                        lighter200: indigo[200],
                        lightest: indigo[100],
                        background: indigo[100],
                        lightest50: indigo[50],
                        backgroundLight: indigo[50],
                    },
                    secondary: {
                        // darkest: purple[800],
                        darker: purple[700],
                        dark: purple[600],
                        main: purple[500],
                        light: purple[400],
                        lighter: purple[300],
                        lighter200: purple[200],
                        lightest: purple[100],
                        background: purple[100],
                        lightest50: purple[50],
                        backgroundLight: purple[50],
                    },
                    info: {
                        darker: blue[700],
                        dark: blue[600],
                        main: blue[500],
                        default: blue[500],
                        light: blue[400],
                        lighter: blue[300],
                        //lighter200: blue[200],
                        lightest: blue[100],
                        background: blue[100],
                        lightest50: blue[50],
                        backgroundLight: blue[50],
                    },
                    success: {
                        darker: green[700],
                        dark: green[600],
                        main: green[500],
                        default: green[500],
                        light: green[400],
                        lighter: green[300],
                        lighter200: green[200],
                        lightest: green[100],
                        background: green[100],
                        lightest50: green[50],
                        backgroundLight: green[50],
                    },
                    warning: {
                        darker: orange[700],
                        dark: orange[600],
                        main: orange[500],
                        default: orange[500],
                        light: orange[400],
                        lighter: orange[300],
                        lighter200: orange[200],
                        lightest: orange[100],
                        background: orange[100],
                        lightest50: orange[50],
                        backgroundLight: orange[50],
                    },
                    error: {
                        darker: red[700],
                        dark: red[600],
                        default: red[500],
                        main: red[500],
                        light: red[400],
                        lighter: red[300],
                        lighter200: red[200],
                        // lightest: red[200],
                        lightest: red[100],
                        background: red[100],
                        backgroundLight: red[50],
                        // TODO: check why typescript not working.
                        bad: red[50],
                        contrastText: yellow[100], // <Chip color="error" /> fails without this.
                    },
                    orange: assignPaletteColors(orange),
                    amber: assignPaletteColors(amber),
                    yellow: assignPaletteColors(yellow),
                    lime: assignPaletteColors(lime),
                    emerald: assignPaletteColors(emerald),
                    teal: assignPaletteColors(teal),
                    cyan: assignPaletteColors(cyan),
                    sky: assignPaletteColors(sky),
                    violet: assignPaletteColors(violet),
                    fuchsia: assignPaletteColors(fuchsia),
                    pink: assignPaletteColors(pink),
                    rose: assignPaletteColors(rose),
                    stockType: {
                        stock: {
                            main: purple[500],
                            // purple[300] lightened by 20%
                            stored: '#e0c3fe',
                            // purple[400] lightened by 20%
                            occupied: '#cd9dfd',
                            // mixed blue and purple
                            storedSelected: '#C2ADFD',
                            // mixed blue and purple
                            occupiedSelected: '#9A83FA',
                            occupiedBorder: purple[950],
                        },
                        crossdocking: {
                            main: yellow[400],
                            stored: yellow[200],
                            occupied: yellow[300],
                            // mixed blue[500] and yellow[300] take 30%
                            storedSelected: '#DACF67',
                            // mixed blue[500] and yellow[300] take 50%
                            occupiedSelected: '#B6BE87',
                            occupiedBorder: yellow[700],
                        },
                    },
                }),
    };
};
const assignPaletteColors = (color) => {
    return {
        // darkest: color[800],
        darker: color[700],
        dark: color[600],
        main: color[500],
        light: color[400],
        lighter: color[300],
        lightest: color[200],
        // TODO: remove `lighter200`
        lighter200: color[200],
        background: color[100],
        backgroundLight: color[50],
        /**
         * `<Chip color="color" />` fails without this.
         */
        contrastText: color[100],
    };
};
