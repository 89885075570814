import { alpha, chipClasses } from '@mui/material';
const getChipBoxShadow = (color) => 
// TODO: to delete later
// TODO: CHECK if it hasn't broken other styling.
// `rgb(255, 255, 255) 0px 0px 0px 0px inset, ${alpha(color, 0.2)} 0px 0px 0px 1px inset, rgba(0, 0, 0, 0) 0px 0px 0px 0px`;
`rgb(255, 255, 255) 0px 0px 0px 0px inset, ${alpha(color, 0.1)} 0px 0px 0px 1px inset, rgba(0, 0, 0, 0) 0px 0px 0px 0px`;
// TODO: to delete all redundant comments later
export const MuiChip = {
    styleOverrides: {
        sizeMedium: {
            lineHeight: '1rem',
            // lineHeight: '1.25rem',
            padding: '.25rem 0',
        },
        sizeSmall: {
            lineHeight: '1rem',
            padding: '.125rem 0',
            // borderRadius: '.375rem',
        },
        labelMedium: {
            // padding: '0 .5rem',
            padding: '0 .375rem',
        },
        labelSmall: {
            padding: '0 .375rem',
        },
        root: ({ ownerState, theme }) => ({
            height: 'unset',
            display: 'inline-flex',
            alignItems: 'center',
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: '.75rem',
            lineHeight: '1rem',
            padding: '.25rem 0',
            // borderRadius: '9999px',
            border: 'none',
            transitionDelay: '25ms',
        }),
        // Chip with `color='default'` styling.
        outlined: ({ ownerState, theme }) => [
            {
                // Unfortunately this `outlined` styling overrides `color` styling (colorPrimary, colorSecondary...).
                // That's why colors are overridden below.
                backgroundColor: theme.palette.background.light,
                color: theme.palette.text.light,
                boxShadow: getChipBoxShadow(theme.palette.neutral.main),
                ':hover': {
                    borderWidth: '1px',
                },
            },
            theme.applyStyles('dark', {
                backgroundColor: alpha(theme.palette.common.white, 0.05),
                color: theme.palette.text.muted,
                ':hover': {
                    backgroundColor: alpha(theme.palette.common.white, 0.1),
                },
            }),
        ],
        // Chip with `color='default'` styling.
        filled: ({ ownerState, theme }) => [
            {
                // Unfortunately this `filled` styling overrides `color` styling (colorPrimary, colorSecondary...).
                // That's why colors are overridden below.
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.light,
            },
            theme.applyStyles('dark', {
                backgroundColor: alpha(theme.palette.common.white, 0.05),
                color: theme.palette.text.muted,
                ':hover': {
                    backgroundColor: alpha(theme.palette.common.white, 0.1),
                },
            }),
        ],
        deleteIconFilledColorPrimary: ({ ownerState, theme }) => ({
            color: theme.palette.primary.light,
            ':hover': {
                color: theme.palette.primary.main,
            },
        }),
        deleteIconFilledColorSecondary: ({ ownerState, theme }) => ({
            color: theme.palette.secondary.light,
            ':hover': {
                color: theme.palette.secondary.main,
            },
        }),
        deleteIconMedium: ({ ownerState, theme }) => ({
            fontSize: '1rem',
        }),
        // iconMedium: {
        // 	fontSize: '1rem',
        // },
        // colorDefault: ({ ownerState, theme }) => ({
        // 	backgroundColor: theme.palette.background.default, // grey[100], //	#f3f4f6
        // 	color: theme.palette.grey[600],
        // }),
        /*
        // Custom color added to Chip component.
        // @ts-ignore
        colorNeutral: ({ ownerState, theme }) => ({
            backgroundColor: theme.palette.background.default, // grey[100], //	#f3f4f6
            color: theme.palette.grey[600],

            [`&.${chipClasses.clickable}`]: {
                ':hover': {
                    backgroundColor: theme.palette.neutral.lighter,
                },
            },
        }),
        */
        colorPrimary: ({ ownerState, theme }) => [
            {
                backgroundColor: theme.palette.primary.lightest,
                color: theme.palette.primary.darker,
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: theme.palette.primary.lightest,
                    color: theme.palette.primary.darker,
                    boxShadow: getChipBoxShadow(theme.palette.primary.dark),
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: theme.palette.primary.lightest,
                    color: theme.palette.primary.darker,
                },
                [`&.${chipClasses.clickable}`]: {
                    ':hover': {
                        backgroundColor: theme.palette.primary.lighter200,
                    },
                },
            },
            theme.applyStyles('dark', {
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: alpha(theme.palette.primary.main, 0.15),
                    color: theme.palette.primary.light,
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.primary.main, 0.15),
                    color: theme.palette.primary.light,
                },
                [`&.${chipClasses.clickable}`]: {
                    ':hover': {
                        backgroundColor: alpha(theme.palette.primary.main, 0.25),
                    },
                },
            }),
        ],
        colorSecondary: ({ ownerState, theme }) => [
            {
                backgroundColor: theme.palette.secondary.lightest,
                color: theme.palette.secondary.darker,
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: theme.palette.secondary.lightest,
                    color: theme.palette.secondary.darker,
                    boxShadow: getChipBoxShadow(theme.palette.secondary.dark),
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.secondary.main, 0.15),
                    color: theme.palette.secondary.darker,
                    [`&.${chipClasses.clickable}`]: {
                        ':hover': {
                            backgroundColor: alpha(theme.palette.secondary.main, 0.25),
                        },
                    },
                },
            },
            theme.applyStyles('dark', {
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: alpha(theme.palette.secondary.main, 0.15),
                    color: theme.palette.secondary.light,
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.secondary.main, 0.15),
                    color: theme.palette.secondary.light,
                },
                [`&.${chipClasses.clickable}`]: {
                    ':hover': {
                        backgroundColor: alpha(theme.palette.secondary.main, 0.2),
                    },
                },
            }),
        ],
        colorInfo: ({ ownerState, theme }) => [
            {
                backgroundColor: theme.palette.info.lightest,
                color: theme.palette.info.darker,
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: theme.palette.info.lightest,
                    color: theme.palette.info.darker,
                    boxShadow: getChipBoxShadow(theme.palette.info.dark),
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.info.main, 0.15),
                    color: theme.palette.info.darker,
                    [`&.${chipClasses.clickable}`]: {
                        ':hover': {
                            backgroundColor: alpha(theme.palette.info.main, 0.25),
                        },
                    },
                },
            },
            theme.applyStyles('dark', {
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: alpha(theme.palette.info.main, 0.15),
                    color: theme.palette.info.light,
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.info.main, 0.15),
                    color: theme.palette.info.light,
                },
                [`&.${chipClasses.clickable}`]: {
                    ':hover': {
                        backgroundColor: alpha(theme.palette.info.main, 0.25),
                    },
                },
            }),
        ],
        colorSuccess: ({ ownerState, theme }) => [
            {
                backgroundColor: theme.palette.success.lightest,
                color: theme.palette.success.darker,
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: theme.palette.success.lightest,
                    color: theme.palette.success.darker,
                    boxShadow: getChipBoxShadow(theme.palette.success.dark),
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.success.main, 0.15),
                    color: theme.palette.success.darker,
                    [`&.${chipClasses.clickable}`]: {
                        ':hover': {
                            backgroundColor: alpha(theme.palette.success.main, 0.25),
                        },
                    },
                },
            },
            theme.applyStyles('dark', {
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: alpha(theme.palette.success.main, 0.1),
                    color: theme.palette.success.light,
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.success.main, 0.1),
                    color: theme.palette.success.light,
                },
                [`&.${chipClasses.clickable}`]: {
                    ':hover': {
                        backgroundColor: alpha(theme.palette.success.main, 0.2),
                    },
                },
            }),
        ],
        colorWarning: ({ ownerState, theme }) => [
            {
                backgroundColor: theme.palette.warning.lightest,
                color: theme.palette.warning.darker,
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: theme.palette.warning.lightest,
                    color: theme.palette.warning.darker,
                    boxShadow: getChipBoxShadow(theme.palette.warning.dark),
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: theme.palette.warning.lightest,
                    color: theme.palette.warning.darker,
                },
            },
            theme.applyStyles('dark', {
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: alpha(theme.palette.warning.main, 0.1),
                    color: theme.palette.warning.light,
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.warning.main, 0.1),
                    color: theme.palette.warning.light,
                },
                [`&.${chipClasses.clickable}`]: {
                    ':hover': {
                        backgroundColor: alpha(theme.palette.warning.main, 0.2),
                    },
                },
            }),
        ],
        colorError: ({ ownerState, theme }) => [
            {
                backgroundColor: theme.palette.error.backgroundLight,
                color: theme.palette.error.darker,
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: theme.palette.error.backgroundLight,
                    color: theme.palette.error.darker,
                    boxShadow: getChipBoxShadow(theme.palette.error.dark),
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.error.main, 0.15),
                    color: theme.palette.error.darker,
                    [`&.${chipClasses.clickable}`]: {
                        ':hover': {
                            backgroundColor: alpha(theme.palette.error.main, 0.25),
                        },
                    },
                },
            },
            theme.applyStyles('dark', {
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: alpha(theme.palette.error.main, 0.1),
                    color: theme.palette.error.light,
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.error.main, 0.1),
                    color: theme.palette.error.light,
                },
                [`&.${chipClasses.clickable}`]: {
                    ':hover': {
                        backgroundColor: alpha(theme.palette.error.main, 0.2),
                    },
                },
            }),
        ],
        // Custom colors added to Chip component.
        colorOrange: ({ ownerState, theme }) => [
            {
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: theme.palette.orange.lightest,
                    color: theme.palette.orange.darker,
                    boxShadow: getChipBoxShadow(theme.palette.orange.dark),
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.orange.main, 0.15),
                    color: theme.palette.orange.darker,
                    [`&.${chipClasses.clickable}`]: {
                        ':hover': {
                            backgroundColor: alpha(theme.palette.orange.main, 0.25),
                        },
                    },
                },
            },
            theme.applyStyles('dark', {
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: alpha(theme.palette.warning.main, 0.1),
                    color: theme.palette.warning.light,
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.warning.main, 0.1),
                    color: theme.palette.warning.light,
                },
                [`&.${chipClasses.clickable}`]: {
                    ':hover': {
                        backgroundColor: alpha(theme.palette.warning.main, 0.2),
                    },
                },
            }),
        ],
        colorAmber: ({ ownerState, theme }) => [
            {
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: theme.palette.amber.lightest,
                    color: theme.palette.amber.darker,
                    boxShadow: getChipBoxShadow(theme.palette.amber.dark),
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.amber.light, 0.2),
                    color: theme.palette.amber.darker,
                    [`&.${chipClasses.clickable}`]: {
                        ':hover': {
                            backgroundColor: alpha(theme.palette.amber.light, 0.3),
                        },
                    },
                },
            },
            theme.applyStyles('dark', {
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: alpha(theme.palette.amber.light, 0.1),
                    color: theme.palette.amber.light,
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.amber.light, 0.1),
                    color: theme.palette.amber.light,
                },
                [`&.${chipClasses.clickable}`]: {
                    ':hover': {
                        backgroundColor: alpha(theme.palette.amber.light, 0.15),
                    },
                },
            }),
        ],
        colorYellow: ({ ownerState, theme }) => [
            {
                // backgroundColor: theme.palette.yellow.background,
                backgroundColor: alpha(theme.palette.yellow.main, 0.15),
                color: theme.palette.yellow.darker,
                // [`&.${chipClasses.clickable}`]: {
                // 	':hover': {
                // 		backgroundColor: alpha(theme.palette.yellow.light, .2),
                // 	},
                // },
                [`&.${chipClasses.outlined}`]: {
                    boxShadow: getChipBoxShadow(theme.palette.yellow.dark),
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.yellow.light, 0.2),
                    color: theme.palette.yellow.darker,
                    [`&.${chipClasses.clickable}`]: {
                        ':hover': {
                            backgroundColor: alpha(theme.palette.yellow.light, 0.3),
                        },
                    },
                },
            },
            theme.applyStyles('dark', {
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: alpha(theme.palette.yellow.light, 0.1),
                    color: theme.palette.yellow.lighter,
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.yellow.light, 0.1),
                    color: theme.palette.yellow.lighter,
                },
                [`&.${chipClasses.clickable}`]: {
                    ':hover': {
                        backgroundColor: alpha(theme.palette.yellow.light, 0.15),
                    },
                },
            }),
        ],
        colorLime: ({ ownerState, theme }) => [
            {
                [`&.${chipClasses.outlined}`]: {
                    boxShadow: getChipBoxShadow(theme.palette.lime.dark),
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.lime.light, 0.2),
                    color: theme.palette.lime.darker,
                    [`&.${chipClasses.clickable}`]: {
                        ':hover': {
                            backgroundColor: alpha(theme.palette.lime.light, 0.3),
                        },
                    },
                },
            },
            theme.applyStyles('dark', {
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: alpha(theme.palette.lime.light, 0.1),
                    color: theme.palette.lime.lighter,
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.lime.light, 0.1),
                    color: theme.palette.lime.lighter,
                },
                [`&.${chipClasses.clickable}`]: {
                    ':hover': {
                        backgroundColor: alpha(theme.palette.lime.light, 0.15),
                    },
                },
            }),
        ],
        colorEmerald: ({ ownerState, theme }) => [
            {
                [`&.${chipClasses.outlined}`]: {
                    boxShadow: getChipBoxShadow(theme.palette.emerald.dark),
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.emerald.main, 0.15),
                    color: theme.palette.emerald.darker,
                    [`&.${chipClasses.clickable}`]: {
                        ':hover': {
                            backgroundColor: alpha(theme.palette.emerald.main, 0.25),
                        },
                    },
                },
            },
            theme.applyStyles('dark', {
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: alpha(theme.palette.emerald.main, 0.1),
                    color: theme.palette.emerald.light,
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.emerald.main, 0.1),
                    color: theme.palette.emerald.light,
                },
                [`&.${chipClasses.clickable}`]: {
                    ':hover': {
                        backgroundColor: alpha(theme.palette.emerald.main, 0.2),
                    },
                },
            }),
        ],
        colorTeal: ({ ownerState, theme }) => [
            {
                [`&.${chipClasses.outlined}`]: {
                    boxShadow: getChipBoxShadow(theme.palette.teal.dark),
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.teal.light, 0.2),
                    color: theme.palette.teal.darker,
                    [`&.${chipClasses.clickable}`]: {
                        ':hover': {
                            backgroundColor: alpha(theme.palette.teal.light, 0.3),
                        },
                    },
                },
            },
            theme.applyStyles('dark', {
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: alpha(theme.palette.teal.main, 0.1),
                    color: theme.palette.teal.lighter,
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.teal.main, 0.1),
                    color: theme.palette.teal.lighter,
                },
                [`&.${chipClasses.clickable}`]: {
                    ':hover': {
                        backgroundColor: alpha(theme.palette.teal.main, 0.2),
                    },
                },
            }),
        ],
        colorCyan: ({ ownerState, theme }) => [
            {
                [`&.${chipClasses.outlined}`]: {
                    boxShadow: getChipBoxShadow(theme.palette.cyan.dark),
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.cyan.main, 0.15),
                    color: theme.palette.cyan.darker,
                    [`&.${chipClasses.clickable}`]: {
                        ':hover': {
                            backgroundColor: alpha(theme.palette.cyan.main, 0.25),
                        },
                    },
                },
            },
            theme.applyStyles('dark', {
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: alpha(theme.palette.cyan.light, 0.1),
                    color: theme.palette.cyan.lighter,
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.cyan.light, 0.1),
                    color: theme.palette.cyan.lighter,
                },
                [`&.${chipClasses.clickable}`]: {
                    ':hover': {
                        backgroundColor: alpha(theme.palette.cyan.light, 0.15),
                    },
                },
            }),
        ],
        colorSky: ({ ownerState, theme }) => [
            {
                [`&.${chipClasses.outlined}`]: {
                    boxShadow: getChipBoxShadow(theme.palette.sky.dark),
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.sky.main, 0.15),
                    color: theme.palette.sky.darker,
                    [`&.${chipClasses.clickable}`]: {
                        ':hover': {
                            backgroundColor: alpha(theme.palette.sky.main, 0.25),
                        },
                    },
                },
            },
            theme.applyStyles('dark', {
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: alpha(theme.palette.sky.main, 0.1),
                    color: theme.palette.sky.lighter,
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.sky.main, 0.1),
                    color: theme.palette.sky.lighter,
                },
                [`&.${chipClasses.clickable}`]: {
                    ':hover': {
                        backgroundColor: alpha(theme.palette.sky.main, 0.2),
                    },
                },
            }),
        ],
        colorViolet: ({ ownerState, theme }) => [
            {
                [`&.${chipClasses.outlined}`]: {
                    boxShadow: getChipBoxShadow(theme.palette.violet.dark),
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.violet.main, 0.15),
                    color: theme.palette.violet.darker,
                    [`&.${chipClasses.clickable}`]: {
                        ':hover': {
                            backgroundColor: alpha(theme.palette.violet.main, 0.25),
                        },
                    },
                },
            },
            theme.applyStyles('dark', {
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: alpha(theme.palette.violet.main, 0.15),
                    color: theme.palette.violet.light,
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.violet.main, 0.15),
                    color: theme.palette.violet.light,
                },
                [`&.${chipClasses.clickable}`]: {
                    ':hover': {
                        backgroundColor: alpha(theme.palette.violet.main, 0.2),
                    },
                },
            }),
        ],
        colorFuchsia: ({ ownerState, theme }) => [
            {
                // // backgroundColor: theme.palette.fuchsia.background,
                // backgroundColor: alpha(theme.palette.fuchsia.main, 0.15),
                // color: theme.palette.fuchsia.darker,
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: theme.palette.fuchsia.lightest,
                    color: theme.palette.fuchsia.darker,
                    boxShadow: getChipBoxShadow(theme.palette.fuchsia.dark),
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.fuchsia.light, 0.15),
                    color: theme.palette.fuchsia.darker,
                    [`&.${chipClasses.clickable}`]: {
                        ':hover': {
                            backgroundColor: alpha(theme.palette.fuchsia.light, 0.25),
                        },
                    },
                },
            },
            theme.applyStyles('dark', {
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: alpha(theme.palette.fuchsia.light, 0.1),
                    color: theme.palette.fuchsia.light,
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.fuchsia.light, 0.1),
                    color: theme.palette.fuchsia.light,
                },
                [`&.${chipClasses.clickable}`]: {
                    ':hover': {
                        backgroundColor: alpha(theme.palette.fuchsia.light, 0.2),
                    },
                },
            }),
        ],
        colorPink: ({ ownerState, theme }) => [
            {
                // backgroundColor: theme.palette.pink.background,
                backgroundColor: alpha(theme.palette.pink.main, 0.15),
                color: theme.palette.pink.darker,
                [`&.${chipClasses.clickable}`]: {
                    ':hover': {
                        backgroundColor: alpha(theme.palette.pink.light, 0.25),
                    },
                },
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: theme.palette.pink.lightest,
                    color: theme.palette.pink.darker,
                    boxShadow: getChipBoxShadow(theme.palette.pink.dark),
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.pink.light, 0.15),
                    color: theme.palette.pink.darker,
                },
            },
            theme.applyStyles('dark', {
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: alpha(theme.palette.pink.light, 0.1),
                    color: theme.palette.pink.light,
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.pink.light, 0.1),
                    color: theme.palette.pink.light,
                },
                [`&.${chipClasses.clickable}`]: {
                    ':hover': {
                        backgroundColor: alpha(theme.palette.pink.light, 0.2),
                    },
                },
            }),
        ],
        colorRose: ({ ownerState, theme }) => [
            {
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: theme.palette.rose.lightest,
                    color: theme.palette.rose.darker,
                    boxShadow: getChipBoxShadow(theme.palette.rose.dark),
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.rose.light, 0.15),
                    color: theme.palette.rose.darker,
                    [`&.${chipClasses.clickable}`]: {
                        ':hover': {
                            backgroundColor: alpha(theme.palette.rose.light, 0.25),
                        },
                    },
                },
            },
            theme.applyStyles('dark', {
                [`&.${chipClasses.outlined}`]: {
                    backgroundColor: alpha(theme.palette.rose.light, 0.1),
                    color: theme.palette.rose.light,
                },
                [`&.${chipClasses.filled}`]: {
                    backgroundColor: alpha(theme.palette.rose.light, 0.1),
                    color: theme.palette.rose.light,
                },
                [`&.${chipClasses.clickable}`]: {
                    ':hover': {
                        backgroundColor: alpha(theme.palette.rose.light, 0.2),
                    },
                },
            }),
        ],
        /*
        // TODO: to delete later
        orange: ChipPropsColorOverrides['orange'];
        // amber
        amber: ChipPropsColorOverrides['amber'];
        // yellow
        yellow: ChipPropsColorOverrides['yellow'];
        // lime
        lime: ChipPropsColorOverrides['lime'];
        // green = success
        // emerald
        emerald: ChipPropsColorOverrides['emerald'];
        // teal
        teal: ChipPropsColorOverrides['teal'];
        // cyan
        cyan: ChipPropsColorOverrides['cyan'];
        // sky
        sky: ChipPropsColorOverrides['sky'];
        // blue = info
        // indigo = primary
        // violet
        violet: ChipPropsColorOverrides['violet'];
        // purple = secondary
        // fuchsia
        fuchsia: ChipPropsColorOverrides['fuchsia'];
        // pink
        pink: ChipPropsColorOverrides['pink'];
        // rose
        rose: ChipPropsColorOverrides['rose'];
        */
    },
};
