import { alpha } from '@mui/material';
import { getButtonAndInputBoxShadow, getButtonAndInputBoxShadowFocused, getErrorInputBoxShadow, getErrorInputBoxShadowFocused, } from '../shadows';
export const MuiOutlinedInput = {
    styleOverrides: {
        root: ({ ownerState, theme }) => [
            {
                height: 'fit-content',
                backgroundColor: theme.palette.background.paper,
                // Adornment color only
                color: theme.palette.text.muted,
                '&.Mui-disabled': {
                    backgroundColor: theme.palette.background.light,
                },
                'input[type="search"]::-webkit-search-cancel-button': {
                    // '-webkit-appearance': 'none',
                    appearance: 'none',
                    height: '16px',
                    width: '16px',
                    marginLeft: '.4rem',
                    backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>")`,
                    cursor: 'pointer',
                },
            },
            // theme.applyStyles('dark', {
            // 	':hover': {
            // 		[`.${outlinedInputClasses.notchedOutline}`]: {
            // 			// border: 'none',
            // 			borderColor: alpha(theme.palette.common.white, 0.2),
            // 		},
            // 	},
            // }),
        ],
        notchedOutline: ({ ownerState, theme }) => [
            {
                // borderColor: 'transparent',
                border: 0,
                boxShadow: getButtonAndInputBoxShadow(theme),
                '.Mui-error &': {
                    borderRadius: theme.shape.borderRadius,
                    boxShadow: getErrorInputBoxShadow(theme),
                },
                '.Mui-focused &': {
                    boxShadow: getButtonAndInputBoxShadowFocused(theme),
                },
                '.Mui-focused.Mui-error &': {
                    boxShadow: getErrorInputBoxShadowFocused(theme),
                },
            },
            theme.applyStyles('dark', {
                backgroundColor: alpha(theme.palette.common.white, 0.05),
                // borderColor: alpha(theme.palette.common.white, 0.1),
                // borderStyle: 'solid',
                // borderWidth: 1,
                // boxShadow: `rgba(0, 0, 0, 0) 0px 0px 0px 0px,rgba(0, 0, 0, 0) 0px 0px 0px 0px,rgba(0, 0, 0, 0) 0px 0px 0px 0px,rgba(0, 0, 0, 0) 0px 0px 0px 0px,rgba(255, 255, 255, 0.1) 0px 0px 0px 1px`,
                // boxShadow: `rgb(255, 255, 255) 0px 0px 0px 0px inset, ${theme.palette.zinc[700]} 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px`,
            }),
        ],
        input: {
            padding: '0.375rem 0.75rem',
            paddingTop: '0.375rem',
            paddingBottom: '0.375rem',
            paddingRight: '0.75rem',
            paddingLeft: '0.75rem',
        },
        multiline: {
            padding: '.25rem 0',
        },
        inputSizeSmall: {
            paddingTop: '0.25rem',
            paddingBottom: '0.25rem',
        },
        // Working example of 'inputSizeSmall', but another way.
        // sizeSmall: {
        // 	input: {
        // 		paddingTop: '0.25rem',
        // 		paddingBottom: '0.25rem',
        // 	},
        // },
    },
};
