import { alpha, backdropClasses, dialogTitleClasses } from '@mui/material';
export const MuiDialog = {
    styleOverrides: {
        root: ({ theme }) => ({
            [`> .${backdropClasses.root}`]: {
                borderRadius: 0,
                backgroundColor: alpha(theme.palette.text.primary, 0.25),
                ...theme.applyStyles('dark', {
                    // catalyst style not working
                    // backgroundColor: alpha(theme.palette.text.primary, 0.5),
                    backgroundColor: `rgba(0,0,0,0.5)`,
                }),
            },
        }),
        paper: ({ theme }) => ({
            // borderRadius: theme.shape.borderRadius,
            borderRadius: theme.spacing(3), // spacing(4) - catalyst dialog default value
            boxShadow: theme.shadows[12],
        }),
    },
};
export const MuiDialogTitle = {
    defaultProps: {
        component: 'div',
    },
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            padding: '.75rem 1.5rem',
            minHeight: '3.75rem',
            backgroundColor: theme.palette.background.light,
            // Overridden by DialogTitleCaption and DialogTitleEntityId components
            // '.MuiTypography-h4': {
            // 	lineHeight: '2.25rem',
            // },
            // '.MuiTypography-h3': {
            // 	lineHeight: '2.1rem',
            // },
        }),
    },
};
export const MuiDialogContent = {
    styleOverrides: {
        root: {
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem 1.5rem',
            [`.${dialogTitleClasses.root}+&`]: {
                paddingTop: '1rem',
            },
        },
    },
};
export const MuiDialogContentText = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            color: theme.palette.text.muted,
            lineHeight: '1.5rem',
            marginTop: '.5rem',
        }),
    },
};
export const MuiDialogActions = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            padding: '.75rem 1.5rem',
            backgroundColor: theme.palette.background.light,
            '> :not(:first-of-type)': {
                marginLeft: '.75rem',
            },
        }),
    },
};
